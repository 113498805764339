<template>
  <v-container :key='custId' class='pt-8'>

    <v-row>

      <v-col cols=12 lg=9>

        <v-card class=''>
          <v-card-title class='primary white--text text-body-1 py-0 pr-0'>
            <v-icon dark class='mr-2'>mdi-note</v-icon>
            Notes
            <v-spacer />
            <v-btn text dark fab small @click='()=>$refs.notes.clickNewNote()'><v-icon>mdi-plus</v-icon></v-btn>
          </v-card-title>
          <v-card-text class='mt-4'>
            <Notes parentType='job' ref='notes' :parentUrl='`customers/${custId}`' hideAddButton />
          </v-card-text>
        </v-card>

        <v-card class='mt-4'>
          <v-card-title class='primary white--text text-body-1 py-0 pr-0'>
            <v-icon dark class='mr-2'>mdi-briefcase</v-icon>
            Jobs
            <v-spacer />
            <v-btn text dark fab small @click='()=>$refs.jobs.clickNewJob()'><v-icon>mdi-plus</v-icon></v-btn>
          </v-card-title>
          <v-card-text class='mt-4'>
            <Jobs :custId='custId' ref='jobs' />
          </v-card-text>
        </v-card>

      </v-col>
      <v-col cols=12 lg=3>
        <Addresses :custId='custId' />
        <Contacts :custId='custId' class='mt-4' />

        <v-card class='mt-4' :loading='loading' :disabled='loading'>
          <v-card-title class='primary white--text text-body-1 py-0 pr-0'>
            <v-icon dark class='mr-2'>mdi-cog</v-icon>
            <span class=''>Options</span>
            <v-spacer />
            <v-btn text dark fab small ></v-btn>
          </v-card-title>
          <v-card-text class='pa-0'>
            <v-list dense class='transparent'>
              <v-list-item link @click='editCustomerName'>
                <v-list-item-content>
                  <v-list-item-title class='primary--text'>
                    <v-icon small class='primary--text mr-2'>mdi-pencil</v-icon>
                    Change customer name
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

      </v-col>

    </v-row>

  </v-container>
</template>

<style lang="scss">
.v-main{ overflow-y: auto; }
</style>


<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import Contacts from '@/components/Customer/Contacts';
import Addresses from '@/components/Customer/Addresses';
import Notes from '@/components/Common/Notes';
import Jobs from '@/components/Customer/Jobs';
export default {
  name: 'Customer',
  components: {
    Contacts, Addresses, Notes, Jobs,
  },
  props: {
    custId: { type: String, required: true, },
  },
  data(){ return {
    loading: false,
    customer: {
      name: null,
    },
    tab: null,
  }},
  watch: {
    custId(){ this.refreshCustomer(); },
    $route: { handler(){ this.SET_TITLE('...'); }, immediate: true },
    'customer.name': { handler(name){ this.SET_TITLE(name); }},
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "PROMPT"]),
    ...mapActions("API", ["API"]),

    refreshCustomer(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: `customers/${this.custId}` })
      .then(customer => { this.customer = customer; })
      .finally(() => { this.loading = false; });
    },

    editCustomerName(){
      this.PROMPT({ title: "Edit Customer", message: 'Customer Name:', placeholder: this.customer.name })
      .then(name => {
        if(!name || name == this.customer.name) return;
        this.updateCustomer({ name });
      });
    },

    updateCustomer(newData){
      let data = { ...this.customer, ...newData };
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `customers/${this.custId}`, data })
      .then(this.refreshCustomer)
      .finally(() => { this.loading = false; });
    },

  },
  mounted(){
    this.refreshCustomer();
  }
}
</script>
