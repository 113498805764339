<template>
  <v-card elevation=0 tile class=''>

    <v-row class='mt-1'>
      <v-col cols=12 class='pb-0'>
        <v-card flat class='py-0'>
          <div class='d-flex'>
            <v-text-field
              placeholder='Filter...' append-icon='mdi-magnify'
              dense outlined rounded hide-details clearable
              class='mr-4' style='max-width:400px;'
              v-model='filter'
            />
            <v-chip-group v-model='filterType' outlind multiple mandatory rounded dark>
              <v-chip filter small value='customer'>Cust/Acc</v-chip>
              <v-chip filter small value='address'>Address</v-chip>
            </v-chip-group>

            <v-btn icon @click='accepted = !accepted' title='Accepted Jobs'>
              <v-icon :color='accepted?"primary":"grey lighten-1"'>mdi-check-bold</v-icon>
            </v-btn>

            <v-btn icon @click='closed = !closed' title='Closed Jobs' class='mr-4 float-left'>
              <v-icon :color='closed?"primary":"grey lighten-1"'>mdi-archive{{closed?'-eye':''}}</v-icon>
            </v-btn>
          </div>

        </v-card>
      </v-col>
    </v-row>

    <v-row ref='sortable'>
      <v-col>
        <v-data-table
          :loading='loading'
          hide-default-footer disable-sort
          :items-per-page='-1'
          :items='jobs'
          :headers='headers'
          @click:row='clickJob'
          class='jobTable'
        >
          <template v-slot:item.number="{item}">
            <v-tooltip bottom>
              <template v-slot:activator='{on,attrs}'>
                <span v-on='on' v-bind='attrs'>{{item.number}}</span>
              </template>
              <span>{{item.description}}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.created_at="{item}">
            <v-tooltip bottom>
              <template v-slot:activator='{on,attrs}'>
                <span v-on='on' v-bind='attrs'>{{moment(item.created_at).format('MMM D, Y')}}</span>
              </template>
              <span class='font-italic text-caption'>{{moment(item.created_at).from()}}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.date_promised="{item}">
            <v-tooltip v-if='item.date_promised' bottom>
              <template v-slot:activator='{on,attrs}'>
                <span v-on='on' v-bind='attrs'>{{moment(item.date_promised).format('MMM D, Y')}}</span>
              </template>
              <span class='font-italic text-caption'>{{moment(item.date_promised).from()}}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.customer_acc="{item}">
            <div v-if='item.customer_acc' class=''>{{item.customer_acc}}</div>
            <span v-else class='text-caption font-italic'>No Account</span>
          </template>
          <template v-slot:item.address="{value}">
            <span v-if='value?(value.line_1):false'>
              {{[value.line_1, value.line_2, value.line3].filter(t=>t).join(',')}}<br />
              {{[value.suburb, value.state, value.postcode].filter(t=>t).join(' ')}}
            </span>
            <span v-else class='text-caption font-italic'>No Address</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12 class='' style=''>
        <v-pagination v-model="page" :length="paginate_pages" :total-visible='15' @input='onPaginate' />
      </v-col>
    </v-row>

  </v-card>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.jobTable{
  tbody td:hover{ cursor: pointer; }
}
</style>

<script>
import Moment from 'moment';
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'Notes',
  components: {},
  props: {
    'custId': { type: String, required: true, },
  },
  data(){ return {
      loading: false,
      jobs: [],
      closed: false,
      accepted: true,
      filter: '',
      filterType: ["customer", "address"],
      paginate_total: 0,
      paginate_pages: 0,
      page: 1,
      headers: [
        { text: "Job", value: 'number' },
        { text: "Account", value: 'customer_acc' },
        { text: "Address", value: 'address' },
        { text: "Created", value: 'created_at' },
        { text: "Forecast", value: 'date_promised' },
        { text: "Stage", value: 'stage.title' }
      ],
  }},
  computed: {},
  watch: {
    closed(){ this.page = 1; this.refreshJobs(); },
    accepted(){ this.page = 1; this.refreshJobs(); },
    filter(){ this.page = 1; this.refreshJobs(true); },
    filterType(){ this.page = 1; this.refreshJobs(true); },
  },
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["CONFIRM", "NAVIGATE"]),

    refreshJobs(debounce){
      this.loading = true;
      let autosave = debounce ? 500 : null;
      let data = {
        customer: this.custId, closed: this.closed, accepted: this.accepted,
        filter: this.filter, filterType: this.filterType
      };
      this.API({ method: 'POST', endpoint: `jobs/filter?page=${this.page}`, data, autosave })
      .then(results => {
        this.jobs = results.data;
        this.paginate_total = results.total;
        this.paginate_pages = results.last_page;
      })
      .finally(() => { this.loading = false; });
    },

    clickNewJob(){
      this.CONFIRM({ title: "Confirm", message: 'Create a new job?' })
      .then(() => {
        let data = { customer: this.custId };
        this.loading = true;
        this.API({ method: 'POST', endpoint: 'jobs/create', data })
        .then(this.clickJob)
        .finally(() => { this.loading = false; });
      });
    },

    clickJob(job){
      this.NAVIGATE(`/dash/jobs/all/${job.xid}`);
    },

    onPaginate(){
      this.refreshJobs();
      this.$parent.$parent.$parent.$el.scrollTop = this.$el.getBoundingClientRect().top;
    },

    moment(m){ return new Moment(m); },

  },
  mounted(){
    this.refreshJobs();
  }
}
</script>
