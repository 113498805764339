<template>
  <v-card elevation=0 :disabled='loading' tile class='transparent'>

    <v-row class='mt-0 pt-0 mb-0' ref='sortable'>

      <v-col cols=12 xsm=6 xlg=4 xxl=3 v-for='address in addresses' v-bind:key='address.xid' :sortable-id='address.xid' class='pt-0'>
        <v-card style='' class='rounded mt-0' :loading='loading' :disabled='loading'>
          <v-card-title class='primary white--text pl-2 pr-0 py-0 mb-0 mt-0'>
            <v-icon color='white' class='mr-2 drag'>mdi-drag</v-icon>
            <span class='text-body-1'> <v-icon color='white' class='mr-2'>mdi-map-marker</v-icon>{{address.label}}</span>
            <v-spacer />
            <v-menu offset-y>
              <template v-slot:activator="{on,attrs}">
                <v-btn fab small text v-on="on" v-bind="attrs" @click.stop.prevent="()=>{}"><v-icon color='white'>mdi-dots-vertical</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item link @click='clickEdit(address)'><v-icon class='primary--text mr-2'>mdi-pencil</v-icon> Edit</v-list-item>
                <v-list-item link @click='clickDelete(address)'><v-icon class='error--text mr-2'>mdi-trash-can</v-icon> Delete</v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text class='py-1'>
            <p class='secondary--text ma-0 py-1'>
              <span v-if='address.line_1'>{{address.line_1}}<br/></span>
              <span v-if='address.line_2'>{{address.line_2}}<br/></span>
              <span v-if='address.line_3'>{{address.line_3}}<br/></span>
              <span v-if='address.suburb'>{{address.suburb}}<br/></span>
              <span v-if='address.state'>{{address.state}}</span>
              <span v-if='address.state && address.postcode'>, </span>
              <span v-if='address.postcode'>{{address.postcode}}</span>
              <span v-if='address.state || address.postcode'><br /></span>
              <span v-if='address.country'>{{address.country}}</span>
            </p>
          </v-card-text>
        </v-card>

      </v-col>

      <v-col cols=12 xmd=6 xlg=4 xxl=3 sortable-id='add' class='pb-0 pt-0'>
        <v-card v-if='!addresses.length' class='fill-height' elevation=0 outlined :loading='loading'>
          <v-container class='fill-height align-center justify-center px-4'>
            <div class='text-center'>
              <div class='my-2 text-caption'>This customer has no addresses!</div>
              <v-btn color='primary' large @click='clickNewAddress'><v-icon>mdi-plus</v-icon>Address</v-btn>
            </div>
          </v-container>
        </v-card>
        <v-card v-else flat class='text-right py-0 transparent'>
          <v-btn small text color='primary' @click='clickNewAddress'>New Address</v-btn>
        </v-card>
      </v-col>

    </v-row>




    <v-dialog scrollable persistent
      :value='dialog.show'
      max-width='600'
    >
      <v-card :loading='dialog.loading' :disabled='dialog.loading' style='max-height:100vh;overflow:auto;'>
        <v-form @submit.prevent='submitDialog'>
          <v-card-title class='primary white--text'>Address Details</v-card-title>
          <v-card-text class='pt-5' style='max-height:450px; overflow-y: auto;'>
            <v-row>
              <v-col cols=12><v-text-field v-model='dialog.label' outlined label='Label' hide-details /></v-col>
              <v-col cols=12><v-text-field v-model='dialog.line_1' outlined label='Address' hide-details /></v-col>
              <v-col cols=12><v-text-field v-model='dialog.line_2' outlined label='Address' hide-details /></v-col>
              <v-col cols=12><v-text-field v-model='dialog.line_3' outlined label='Address' hide-details /></v-col>
              <v-col cols=12 md=6><v-text-field v-model='dialog.suburb' outlined label='Locality' hide-details /></v-col>
              <v-col cols=12 md=3><v-text-field v-model='dialog.state' maxlength=3 outlined label='State' hide-details /></v-col>
              <v-col cols=12 md=3><v-text-field v-model='dialog.postcode' maxlength=5 outlined label='Postcode' hide-details /></v-col>
              <v-col cols=12 md=6><v-text-field v-model='dialog.country' outlined label='Country' hide-details /></v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions class=''>
            <v-spacer />
            <v-btn large color='secondary float-right' width='150' @click='dialog.show=false'>Cancel</v-btn>
            <v-btn large color='primary float-right' width='150' type='submit'>{{dialog.mode}}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.drag{ cursor: move; }
</style>

<script>
import Sortable from 'sortablejs';
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'Contacts',
  components: {},
  props: {
    'custId': { type: String, required: true, },
  },
  data(){ return {
      loading: false,
      addresses: [],
      sortable: null,
      sorting: false,
      //forceFallback: true,
      dialog: {
        show: false, loading: false, mode: '',
        name_first: '', name_last: '', note: '',
        phone_1: '', phone_2: '', email: '',
      },
  }},
  computed: {},
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["CONFIRM"]),

    refreshAddresses(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `customers/${this.custId}/addresses` })
      .then(addresses => { this.addresses = addresses; })
      .finally(() => { this.loading = false; });
    },

    clickNewAddress(){
      let blank = {
        label: "", line_1: "", line_2: "", line_3: "",
        suburb: "Mount Gambier", state: "SA", postcode: "5290", country: "",
      };
      this.dialog = {
        ...this.dialog, ...blank,
        mode: 'create', show: true, loading: false,
      };
    },

    submitDialog(){
      if(this.dialog.mode == 'create') this.createAddress();
      if(this.dialog.mode == 'update') this.updateAddress();
    },

    createAddress(){
      this.dialog.loading = true;
      let { label, line_1, line_2, line_3, suburb, state, postcode, country } = this.dialog;
      let data = { label, line_1, line_2, line_3, suburb, state, postcode, country };
      this.API({ method: 'POST', endpoint: `customers/${this.custId}/addresses/create`, data })
      .then(() => {
        this.dialog.show = false;
        this.refreshAddresses();
      })
      .finally(() => { this.dialog.loading = false; });
    },

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortable, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.drag',
        dragClass: 'dragging',
        onEnd: this.sort,
      });
    },

    sort(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;
      let data = { order: this.sortable.toArray().filter(key => key != 'add') };
      this.sortable.sort(['add', ...data.order ]);
      this.loading = true;
      this.API({ method: 'POST', endpoint: `customers/${this.custId}/addresses/sort`, data })
      .finally(() => { this.loading = false; });
    },

    clickEdit(address){
      this.dialog = {
        ...this.dialog,
        ...address,
        show: true, loading: false, mode: 'update',
      };
    },

    updateAddress(){
      this.dialog.loading = true;
      let { label, line_1, line_2, line_3, suburb, state, postcode, country } = this.dialog;
      let data = { label, line_1, line_2, line_3, suburb, state, postcode, country };
      this.API({ method: 'PATCH', endpoint: `addresses/${this.dialog.xid}`, data })
      .then(() => {
        this.dialog.show = false;
        this.refreshAddresses();
      })
      .finally(() => { this.dialog.loading = false; });
    },

    clickDelete(address){
      let {xid, label } = address;
      this.CONFIRM({
        title: "Delete Address?",
        message: `Are you sure you want to delete the address '${label}'?`
      })
      .then(()=>{
        this.loading = true;
        this.API({ method: 'DELETE', endpoint: `addresses/${xid}` })
        .then(this.refreshAddresses)
        .finally(()=>{ this.loading = false; });
      });
    },

  },
  mounted(){
    this.refreshAddresses();
    this.makeSortable();
  }
}
</script>
