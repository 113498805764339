<template>
  <v-card elevation=0 :disabled='loading' tile class='transparent'>
    <v-row class='mt-1' ref='sortable'>

      <v-col cols=12 xsm=6 xlg=4 xxl=3 v-for='c in contacts' v-bind:key='c.xid' :sortable-id='c.xid' class='pt-0'>
        <v-card style='mt-0' class='rounded'>
          <v-card-title class='primary white--text pl-2 pr-0 py-0 mb-0'>
            <v-icon color='white' class='mr-2 drag'>mdi-drag</v-icon>
            <span class='text-body-1'> <v-icon color='white' class='mr-2'>mdi-account</v-icon> {{c.name_first}} {{c.name_last}}</span>
            <v-spacer />
            <v-menu offset-y>
              <template v-slot:activator="{on,attrs}">
                <v-btn fab small text v-on="on" v-bind="attrs" @click.stop.prevent="()=>{}"><v-icon color='white'>mdi-dots-vertical</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item link @click='clickEdit(c)'><v-icon class='primary--text mr-2'>mdi-pencil</v-icon> Edit</v-list-item>
                <v-list-item link @click='clickDelete(c)'><v-icon class='error--text mr-2'>mdi-trash-can</v-icon> Delete</v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text class='py-1'>
            <v-row class='my-0'>
              <v-col cols=12 class='py-1' v-if='c.note'><span class='text-caption-2 secondary--text'>{{c.note}}</span></v-col>
              <v-col cols=12 md=4 class='py-1'><v-btn block small text color='primary' :disabled='!c.phone_1' @click='phone_1(c)'><v-icon class='mr-2'>mdi-phone</v-icon>Phone</v-btn></v-col>
              <v-col cols=12 md=4 class='py-1'><v-btn block small text color='primary' :disabled='!c.phone_2' @click='phone_2(c)'><v-icon class='mr-2'>mdi-phone</v-icon>Phone #2</v-btn></v-col>
              <v-col cols=12 md=4 class='py-1'><v-btn block small text color='primary' :disabled='!c.email' @click='email(c)'><v-icon class='mr-2'>mdi-email</v-icon>Email</v-btn></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols=12 xmd=6 xlg=4 xxl=3 sortable-id='add' class='pt-0'>
        <v-card v-if='!contacts.length' class='fill-height' elevation=0 outlined :loading='loading'>
          <v-container class='fill-height align-center justify-center px-4'>
            <div class='text-center'>
              <div class='my-2 text-caption'>This customer has no contacts!</div>
              <v-btn color='primary' large @click='clickNewContact'><v-icon>mdi-plus</v-icon>Contact</v-btn>
            </div>
          </v-container>
        </v-card>
        <v-card v-else flat class='text-right py-0 transparent'>
          <v-btn small text color='primary' @click='clickNewContact'>New Contact</v-btn>
        </v-card>
      </v-col>

    </v-row>




    <v-dialog scrollable persistent
      :value='dialog.show'
      max-width='600'
    >
      <v-card :loading='dialog.loading' :disabled='dialog.loading' style='max-height:100vh;overflow:auto;'>
        <v-form @submit.prevent='submitDialog'>
          <v-card-title class='primary white--text'>Contact Details</v-card-title>
          <v-card-text class='pt-5' style='max-height:450px; overflow-y: auto;'>
            <v-row>
              <v-col cols=12 md=6><v-text-field v-model='dialog.name_first' outlined label='First Name' hide-details /></v-col>
              <v-col cols=12 md=6><v-text-field v-model='dialog.name_last' outlined label='Last Name' hide-details /></v-col>
              <v-col cols=12><v-text-field v-model='dialog.note' outlined label='Job Title / Position' hide-details/></v-col>
              <v-col cols=12 md=6><v-text-field v-model='dialog.phone_1' outlined label='Phone' hide-details /></v-col>
              <v-col cols=12 md=6><v-text-field v-model='dialog.phone_2' outlined label='Phone 2' hide-details /></v-col>
              <v-col cols=12><v-text-field v-model='dialog.email' outlined label='Email Address' hide-details /></v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions class=''>
            <v-spacer />
            <v-btn large color='secondary float-right' width='150' @click='dialog.show=false'>Cancel</v-btn>
            <v-btn large color='primary float-right' width='150' type='submit'>{{dialog.mode}}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.drag{ cursor: move; }
</style>

<script>
import Sortable from 'sortablejs';
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'Contacts',
  components: {},
  props: {
    'custId': { type: String, required: true, },
  },
  data(){ return {
      loading: false,
      contacts: [],
      sortable: null,
      sorting: false,
      //forceFallback: true,
      dialog: {
        show: false, loading: false, mode: '',
        name_first: '', name_last: '', note: '',
        phone_1: '', phone_2: '', email: '',
      },
  }},
  computed: {},
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["CONFIRM"]),

    refreshContacts(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `customers/${this.custId}/contacts` })
      .then(contacts => { this.contacts = contacts; })
      .finally(() => { this.loading = false; });
    },

    clickNewContact(){
      this.dialog = {
        ...this.dialog,
        show: true, loading: false, mode: 'create',
        name_first: '', name_last: '', note: '',
        phone_1: '', phone_2: '', email: '',
      };
    },

    submitDialog(){
      if(this.dialog.mode == 'create') this.createContact();
      if(this.dialog.mode == 'update') this.updateContact();
    },

    createContact(){
      this.dialog.loading = true;
      let { name_first, name_last, note, phone_1, phone_2, email } = this.dialog;
      let data = { name_first, name_last, note, phone_1, phone_2, email };
      this.API({ method: 'POST', endpoint: `customers/${this.custId}/contacts/create`, data })
      .then(() => {
        this.dialog.show = false;
        this.refreshContacts();
      })
      .finally(() => { this.dialog.loading = false; });
    },

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortable, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.drag',
        dragClass: 'dragging',
        onEnd: this.sort,
      });
    },

    sort(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;
      let data = { order: this.sortable.toArray().filter(key => key != 'add') };
      this.sortable.sort(['add', ...data.order]);
      this.loading = true;
      this.API({ method: 'POST', endpoint: `customers/${this.custId}/contacts/sort`, data })
      .finally(() => { this.loading = false; });
    },

    clickEdit(contact){
      this.dialog = {
        ...this.dialog,
        ...contact,
        show: true, loading: false, mode: 'update',
      };
    },

    updateContact(){
      this.dialog.loading = true;
      let { name_first, name_last, note, phone_1, phone_2, email } = this.dialog;
      let data = { name_first, name_last, note, phone_1, phone_2, email };
      this.API({ method: 'PATCH', endpoint: `contacts/${this.dialog.xid}`, data })
      .then(() => {
        this.dialog.show = false;
        this.refreshContacts();
      })
      .finally(() => { this.dialog.loading = false; });
    },

    clickDelete(contact){
      let {xid, name_first, name_last } = contact;
      this.CONFIRM({
        title: "Delete Contact?",
        message: `Are you sure you want to delete the contact "${name_first} ${name_last}"?`
      })
      .then(()=>{
        this.loading = true;
        this.API({ method: 'DELETE', endpoint: `contacts/${xid}` })
        .then(this.refreshContacts)
        .finally(()=>{ this.loading = false; });
      });
    },

    phone_1(contact){
      this.CONFIRM({
        title: 'Make Call',
        message: `Call ${contact.name_first} ${contact.name_last} on ${contact.phone_1} ?`,
        yes: 'Call',
        other: 'Copy',
      })
      .then(option=>{
        if(option == 'yes') window.open(`tel:${contact.phone_1}`);
        if(option == 'other') navigator.clipboard.writeText(contact.phone_1);
      })
    },

    phone_2(contact){
      this.CONFIRM({
        title: 'Make Call',
        message: `Call ${contact.name_first} ${contact.name_last} on ${contact.phone_2}?`,
        yes: 'Call',
        other: 'Copy',
      })
      .then(option=>{
        if(option == 'yes') window.open(`tel:${contact.phone_2}`);
        if(option == 'other') navigator.clipboard.writeText(contact.phone_2);
      })
    },

    email(contact){
      this.CONFIRM({
        title: 'Send Email',
        message: `Email ${contact.name_first} ${contact.name_last} at ${contact.email}?`,
        yes: 'Email',
        other: 'Copy',
      })
      .then(option=>{
        if(option == 'yes') window.open(`mailto:${contact.email}`);
        if(option == 'other') navigator.clipboard.writeText(contact.email);
      })
    },

  },
  mounted(){
    this.refreshContacts();
    this.makeSortable();
  }
}
</script>
