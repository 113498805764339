var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    key: _vm.custId,
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('v-card', {}, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-0 pr-0"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-note")]), _vm._v(" Notes "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "dark": "",
      "fab": "",
      "small": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.notes.clickNewNote();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('Notes', {
    ref: "notes",
    attrs: {
      "parentType": "job",
      "parentUrl": "customers/".concat(_vm.custId),
      "hideAddButton": ""
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-0 pr-0"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-briefcase")]), _vm._v(" Jobs "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "dark": "",
      "fab": "",
      "small": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.jobs.clickNewJob();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('Jobs', {
    ref: "jobs",
    attrs: {
      "custId": _vm.custId
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('Addresses', {
    attrs: {
      "custId": _vm.custId
    }
  }), _c('Contacts', {
    staticClass: "mt-4",
    attrs: {
      "custId": _vm.custId
    }
  }), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-0 pr-0"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-cog")]), _c('span', {}, [_vm._v("Options")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "dark": "",
      "fab": "",
      "small": ""
    }
  })], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-list', {
    staticClass: "transparent",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.editCustomerName
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "primary--text"
  }, [_c('v-icon', {
    staticClass: "primary--text mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")]), _vm._v(" Change customer name ")], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }